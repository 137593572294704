<template>
  <section class="follow-us">
    <div class="container">
      <h2>Our Podcast</h2>
      <div class="podcast-section">
        <div class="podcast-content">
          <p>Why does everyone have a podcast nowadays?</p>
          <p>Join us in ours, though, as we traverse through business, tech, and other distractions. </p>
          <ul class="podcast-links">
            <li><a href="https://open.spotify.com/show/3o5YXV4PrwHPstHglMIaG5" target="_blank"><img src="../assets/images/follow-us/spotify.png" alt="spotify"></a></li>
            <li><a href="https://podcasts.google.com/feed/aHR0cHM6Ly9idXNpbmVzc2FuZGNoYWkuY29tL2JjcGcvZmVlZC54bWw?sa=X&ved=2ahUKEwjUs7rlmNHsAhXZhXIEHTYhCmUQ9sEGegQIARAD" target="_blank"><img src="../assets/images/follow-us/google-music.png" alt="google music"></a></li>
            <li><a href="https://podcasts.apple.com/us/podcast/business-chai/id1491562156" target="_blank"><img src="../assets/images/follow-us/podcast.png" alt="apple podcasts"></a></li>
          </ul>
        </div>
        <aplayer
          :music='playlist[0]'
          :list='playlist'
        />
      </div>
    </div>

    <div class="container">
      <h2>Our Blog</h2>
      <div class="blog-section">
        <div class="blog-content">
          <div class="blog-block">
            <h3><a target="_blank" href="https://medium.com/@biscuitandchai/create-a-website-that-tells-a-story-dc8f2cbbecf9">Create a Website That Tells a Story</a></h3>
            <p>It is important to have good content on your website but the purpose of an elevator pitch is to first hook the listener in before you get to tell them your whole story.</p>
            <a class="arrow-link" target="_blank" href="https://medium.com/@biscuitandchai/create-a-website-that-tells-a-story-dc8f2cbbecf9">Read more</a>
          </div>
          <div class="blog-block">
            <h3><a target="_blank" href="https://medium.com/@biscuitandchai/the-budget-love-triangle-a72f6d785b5f">The Budget Love Triangle</a></h3>
            <p>It is 1999, I am waiting in a doctor’s office and see one of those whimsical wooden signs (you know the ones that always have some sort of life lesson or moral on them) which reads …</p>
            <a class="arrow-link" target="_blank" href="https://medium.com/@biscuitandchai/the-budget-love-triangle-a72f6d785b5f">Read more</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <h2>Connect with us</h2>
      <div class="social-media">
        <a href="mailto: apps@biscuitandchai.com">
          <img src="../assets/images/follow-us/email.svg" alt="email">
        </a>
        <a href="https://www.instagram.com/biscuit.chai" target="_blank">
          <img src="../assets/images/follow-us/instagram.svg" alt="instagram">
        </a>
        <a href="https://www.linkedin.com/company/biscuit-&-chai" target="_blank">
          <img src="../assets/images/follow-us/linkedin.svg" alt="linkedin">
        </a>
        <a href="https://twitter.com/biscuitandchai" target="_blank">
          <img src="../assets/images/follow-us/twitter.svg" alt="twitter">
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import Aplayer from 'vue-aplayer'

export default {
  components: {
    Aplayer
  },
  data () {
    return {
      playlist: [
        {
          title: '5. Social Impact',
          src: 'https://businessandchai.com/bcpg/media/2020-11-02_social_impact.mp3'
        },
        {
          title: '4. WFH',
          src: 'https://businessandchai.com/bcpg/media/2020-07-31_podcast5.mp3'
        },
        {
          title: '3. A Looming Shadow',
          src: 'https://businessandchai.com/bcpg/media/2020-03-19_podcast4.mp3'
        },
        {
          title: '2. Learning From Business Failures',
          src: 'https://businessandchai.com/bcpg/media/2020-01-07_learningfrombusinessfailures.mp3'
        },
        {
          title: '1. Naming Your Business',
          src: 'https://businessandchai.com/bcpg/media/2019-12-24_business_and_chai_ep_1.mp3'
        },
        {
          title: '0. Business & Chai',
          src: 'https://businessandchai.com/bcpg/media/2019-12-13_coming_soon_-_business__chai.mp3'
        }
      ]
    }
  }
}
</script>
